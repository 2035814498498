<template>
  <section class="hero is-fullheight">
    <ModalEditProduct
      v-if="modalEditProductActive"
      :active="modalEditProductActive"
      :changeStatus="changeStatus"
      @close-modal="modalEditProductActive = false"
    />
    <ModalEditProductService
      v-if="modalEditProductServiceActive"
      :active="modalEditProductServiceActive"
      :update-product="updateProductAction"
      :data-detail="productServiceDetail"
      @close-modal="modalEditProductServiceActive = false"
    />
    <div class="hero-body is-align-items-flex-start">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <h1 class="title_view">
                    Detalle del producto
                  </h1>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <button class="button btn_aqua is-normal" @click="modalEditProductActive = true">
                    Editar estatus
                  </button>
                </div>
                <div class="level-item">
                  <button class="button btn_violet is-normal" @click="modalEditProductServiceActive = true">
                    Editar producto
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12" v-if="!loading">
            <product-information
              :dataDetail="productServiceDetail"
              :uploadImageProduct="uploadImageProduct"
            />
          </div>
          <div class="column is-12" v-if="!loading">
            <product-user-information
              :dataDetail="productServiceDetail"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ProductDetailMain',
  components: {
    ProductInformation: () => import('@/components/Products/Detail/ProductInformation.vue'),
    ProductUserInformation: () => import('@/components/Products/Detail/ProductUserInformation.vue'),
    ModalEditProduct: () => import('@/components/Products/Detail/ModalEditProduct.vue'),
    ModalEditProductService: () => import('@/components/Products/ModalEditProductService.vue')
  },
  data () {
    return {
      loading: true,
      productId: this.$route.params.productId,
      modalEditProductActive: false,
      productServiceDetail: {},
      modalEditProductServiceActive: false
    }
  },
  methods: {
    ...mapActions(['getProductDetail', 'changeStatusProduct', 'uploadImageProductAc', 'updateDataProduct']),
    async getDetail () {
      this.loading = true
      const resp = await this.getProductDetail(this.productId)
      if (resp.success) {
        this.productServiceDetail = resp.payload
      }
      this.loading = false
    },
    async changeStatus (productInfo) {
      const resp = await this.changeStatusProduct({ productId: this.productId, productInfo })
      if (resp.success) {
        await this.getDetail()
        this.modalEditProductActive = false
      }
    },
    async uploadImageProduct (file) {
      const resp = await this.uploadImageProductAc({ productId: this.productId, image: file })
      if (resp.success) {
        await this.getDetail()
        this.modalEditProductActive = false
      }
    },
    async updateProductAction (dataUpdate) {
      const { success } = await this.updateDataProduct({ dataUpdate: dataUpdate, productId: this.productId })
      if (success) {
        this.getDetail()
        this.modalEditProductServiceActive = false
      }
    }
  },
  beforeMount () {
    this.getDetail()
    this.modalEditProductActive = false
    this.modalEditProductServiceActive = false
  }
}
</script>

<style lang="scss" scoped>

</style>
